import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { Utilities_OperationService } from './Utilities.operation.service';
import { PrintNode_OperationService } from './PrintNode.operation.service';
import { Inventory_OperationService } from './Inventory.operation.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Reports_OperationService } from './Reports.operation.service';
import { FootPrintApiManager_OperationService } from './FootPrintApiManager.operation.service';


@Injectable({ providedIn: 'root' })
export class app_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.Utilities = this.injector.get(Utilities_OperationService);
    this.PrintNode = this.injector.get(PrintNode_OperationService);
    this.Inventory = this.injector.get(Inventory_OperationService);
    this.Invoices = this.injector.get(Invoices_OperationService);
    this.Reports = this.injector.get(Reports_OperationService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_OperationService);
  }

    public Utilities: Utilities_OperationService;
    public PrintNode: PrintNode_OperationService;
    public Inventory: Inventory_OperationService;
    public Invoices: Invoices_OperationService;
    public Reports: Reports_OperationService;
    public FootPrintApiManager: FootPrintApiManager_OperationService;
  public app: app_OperationService = this;


  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/app/operations/${operationName}/isauthorized`, null);
  }
}

import { Inject, Injectable, Injector } from '@angular/core';


import { Invoices_activate_billing_contract_flowService } from './Invoices.flow.index';
import { Invoices_are_billing_contract_dates_valid_flowService } from './Invoices.flow.index';
import { Invoices_billing_rate_delete_flowService } from './Invoices.flow.index';
import { Invoices_billing_rate_insert_or_update_flowService } from './Invoices.flow.index';
import { Invoices_cancel_billing_task_flowService } from './Invoices.flow.index';
import { Invoices_commodity_priceService } from './Invoices.flow.index';
import { Invoices_commodity_price_populateService } from './Invoices.flow.index';
import { Invoices_copy_billing_contract_flowService } from './Invoices.flow.index';
import { Invoices_create_billing_contract_flowService } from './Invoices.flow.index';
import { Invoices_create_billing_contract_line_flowService } from './Invoices.flow.index';
import { Invoices_create_invoice_flowService } from './Invoices.flow.index';
import { Invoices_create_invoice_line_flowService } from './Invoices.flow.index';
import { Invoices_create_or_update_billing_contract_line_detail_flowService } from './Invoices.flow.index';
import { Invoices_create_reasoncode_flowService } from './Invoices.flow.index';
import { Invoices_deactivate_billing_contract_flowService } from './Invoices.flow.index';
import { Invoices_delete_billing_contract_flowService } from './Invoices.flow.index';
import { Invoices_delete_billing_contract_line_flowService } from './Invoices.flow.index';
import { Invoices_delete_billing_task_flowService } from './Invoices.flow.index';
import { Invoices_delete_invoice_flowService } from './Invoices.flow.index';
import { Invoices_delete_invoice_line_flowService } from './Invoices.flow.index';
import { Invoices_delete_invoicing_project_rule_priorityService } from './Invoices.flow.index';
import { Invoices_delete_invoicing_rule_template_exceptionService } from './Invoices.flow.index';
import { Invoices_freight_billing_details_storageService } from './Invoices.flow.index';
import { Invoices_freight_billing_header_storageService } from './Invoices.flow.index';
import { Invoices_freight_billing_process_accessorial_charge_flowService } from './Invoices.flow.index';
import { Invoices_freight_billing_process_ordersService } from './Invoices.flow.index';
import { Invoices_initialize_invoicing_configurationService } from './Invoices.flow.index';
import { Invoices_insert_invoicing_project_rule_priorityService } from './Invoices.flow.index';
import { Invoices_insert_invoicing_rule_template_exceptionService } from './Invoices.flow.index';
import { Invoices_invoicing_confirm_requestsService } from './Invoices.flow.index';
import { Invoices_invoicing_create_invoice_linesService } from './Invoices.flow.index';
import { Invoices_invoicing_create_invoicesService } from './Invoices.flow.index';
import { Invoices_invoicing_create_transactionsService } from './Invoices.flow.index';
import { Invoices_invoicing_instructionService } from './Invoices.flow.index';
import { Invoices_invoicing_logService } from './Invoices.flow.index';
import { Invoices_invoicing_mainService } from './Invoices.flow.index';
import { Invoices_invoicing_optionService } from './Invoices.flow.index';
import { Invoices_invoicing_project_rule_priority_health_checkService } from './Invoices.flow.index';
import { Invoices_invoicing_purgeService } from './Invoices.flow.index';
import { Invoices_invoicing_transactionService } from './Invoices.flow.index';
import { Invoices_invoicing_transaction_testService } from './Invoices.flow.index';
import { Invoices_is_billing_contract_activatable_flowService } from './Invoices.flow.index';
import { Invoices_is_billing_contract_copyable_flowService } from './Invoices.flow.index';
import { Invoices_is_billing_contract_deactivatable_flowService } from './Invoices.flow.index';
import { Invoices_is_billing_contract_deletable_flowService } from './Invoices.flow.index';
import { Invoices_is_billing_contract_line_deletable_flowService } from './Invoices.flow.index';
import { Invoices_is_billing_contract_line_valid_flowService } from './Invoices.flow.index';
import { Invoices_is_billing_task_cancelable_flowService } from './Invoices.flow.index';
import { Invoices_is_billing_task_deletable_flowService } from './Invoices.flow.index';
import { Invoices_is_billing_task_invoicable_flowService } from './Invoices.flow.index';
import { Invoices_is_invoice_cancelableService } from './Invoices.flow.index';
import { Invoices_is_invoice_completableService } from './Invoices.flow.index';
import { Invoices_is_invoice_deletableService } from './Invoices.flow.index';
import { Invoices_is_invoice_exportableService } from './Invoices.flow.index';
import { Invoices_is_invoice_line_deletableService } from './Invoices.flow.index';
import { Invoices_migrate_storage_to_utilitiesService } from './Invoices.flow.index';
import { Invoices_submit_entity_import_json_requestService } from './Invoices.flow.index';
import { Invoices_update_billing_task_flowService } from './Invoices.flow.index';
import { Invoices_update_invoice_status_flowService } from './Invoices.flow.index';
import { Invoices_update_invoicing_project_rule_priorityService } from './Invoices.flow.index';

import { $frontendTypes } from './Invoices.frontend.types'

@Injectable({ providedIn: 'root' })
export class Invoices_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public Invoices: Invoices_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _activate_billing_contract_flow: Invoices_activate_billing_contract_flowService;
  public async activate_billing_contract_flow(inParams: { contractId: number }): Promise< { success?: boolean, messages?: string[] }> {
    if(!this._activate_billing_contract_flow) {
      this._activate_billing_contract_flow = this.injector.get(Invoices_activate_billing_contract_flowService);
    }
    return this._activate_billing_contract_flow.run(inParams);
  }
   
   

   
 
  private _are_billing_contract_dates_valid_flow: Invoices_are_billing_contract_dates_valid_flowService;
  public async are_billing_contract_dates_valid_flow(inParams: { accountId?: number, projectId?: number, startDate: string, endDate: string, contractId: number }): Promise< { result?: string }> {
    if(!this._are_billing_contract_dates_valid_flow) {
      this._are_billing_contract_dates_valid_flow = this.injector.get(Invoices_are_billing_contract_dates_valid_flowService);
    }
    return this._are_billing_contract_dates_valid_flow.run(inParams);
  }
   
   

   
 
  private _billing_rate_delete_flow: Invoices_billing_rate_delete_flowService;
  public async billing_rate_delete_flow(inParams: { billingContractLineId: number, rateId: number, typeId?: number }): Promise< { result?: string, success?: boolean }> {
    if(!this._billing_rate_delete_flow) {
      this._billing_rate_delete_flow = this.injector.get(Invoices_billing_rate_delete_flowService);
    }
    return this._billing_rate_delete_flow.run(inParams);
  }
   
   

   
 
  private _billing_rate_insert_or_update_flow: Invoices_billing_rate_insert_or_update_flowService;
  public async billing_rate_insert_or_update_flow(inParams: { billingContractLineId: number, rateId?: number, rangeStart?: number, rangeEnd?: number, option: string, rate?: number, minimumCharge?: number, typeId?: number }): Promise< { result?: string, rateId?: number }> {
    if(!this._billing_rate_insert_or_update_flow) {
      this._billing_rate_insert_or_update_flow = this.injector.get(Invoices_billing_rate_insert_or_update_flowService);
    }
    return this._billing_rate_insert_or_update_flow.run(inParams);
  }
   
   

   
 
  private _cancel_billing_task_flow: Invoices_cancel_billing_task_flowService;
  public async cancel_billing_task_flow(inParams: { billingTaskId: number, cancelledReasonCodeId?: number, cancelledNotes?: string }): Promise< { reason?: string }> {
    if(!this._cancel_billing_task_flow) {
      this._cancel_billing_task_flow = this.injector.get(Invoices_cancel_billing_task_flowService);
    }
    return this._cancel_billing_task_flow.run(inParams);
  }
   
   

   
 
  private _commodity_price: Invoices_commodity_priceService;
  public async commodity_price(inParams: { payload?: $frontendTypes.Invoices.CommodityPrice[], action: string, skip?: number, take?: number, date_from?: string, date_to?: string, full_text_search?: string }): Promise< { payload?: $frontendTypes.Invoices.CommodityPrice[], success?: boolean, error?: any[], count?: number }> {
    if(!this._commodity_price) {
      this._commodity_price = this.injector.get(Invoices_commodity_priceService);
    }
    return this._commodity_price.run(inParams);
  }
   
   

   
 
  private _commodity_price_populate: Invoices_commodity_price_populateService;
  public async commodity_price_populate(inParams: {  }): Promise< { messages?: any[] }> {
    if(!this._commodity_price_populate) {
      this._commodity_price_populate = this.injector.get(Invoices_commodity_price_populateService);
    }
    return this._commodity_price_populate.run(inParams);
  }
   
   

   
 
  private _copy_billing_contract_flow: Invoices_copy_billing_contract_flowService;
  public async copy_billing_contract_flow(inParams: { contractId?: number, projectId?: number, startDate?: string, endDate?: string }): Promise< { success?: boolean, messages?: string[], contractId?: number }> {
    if(!this._copy_billing_contract_flow) {
      this._copy_billing_contract_flow = this.injector.get(Invoices_copy_billing_contract_flowService);
    }
    return this._copy_billing_contract_flow.run(inParams);
  }
   
   

   
 
  private _create_billing_contract_flow: Invoices_create_billing_contract_flowService;
  public async create_billing_contract_flow(inParams: { projectId: number }): Promise< { reason?: string, billingContractId?: number }> {
    if(!this._create_billing_contract_flow) {
      this._create_billing_contract_flow = this.injector.get(Invoices_create_billing_contract_flowService);
    }
    return this._create_billing_contract_flow.run(inParams);
  }
   
   

   
 
  private _create_billing_contract_line_flow: Invoices_create_billing_contract_line_flowService;
  public async create_billing_contract_line_flow(inParams: { billingContractId: number, billingAggregationStrategyId: number }): Promise< { reason?: string, billingContractLineId?: number }> {
    if(!this._create_billing_contract_line_flow) {
      this._create_billing_contract_line_flow = this.injector.get(Invoices_create_billing_contract_line_flowService);
    }
    return this._create_billing_contract_line_flow.run(inParams);
  }
   
   

   
 
  private _create_invoice_flow: Invoices_create_invoice_flowService;
  public async create_invoice_flow(inParams: { projectId: number, typeId?: number, lookupcode?: string, invoiceDate?: string, dueDate?: string, referenceCode?: string, invoiceTermId?: number, currencyId?: number, notes?: string }): Promise< { reason?: string, invoiceId?: number }> {
    if(!this._create_invoice_flow) {
      this._create_invoice_flow = this.injector.get(Invoices_create_invoice_flowService);
    }
    return this._create_invoice_flow.run(inParams);
  }
   
   

   
 
  private _create_invoice_line_flow: Invoices_create_invoice_line_flowService;
  public async create_invoice_line_flow(inParams: { invoiceId: number, billingCodeId: number, quantity: number, unitPrice: number, minimumCharge: number, notes?: string, description?: string }): Promise< { reason?: string, invoiceLineId?: number }> {
    if(!this._create_invoice_line_flow) {
      this._create_invoice_line_flow = this.injector.get(Invoices_create_invoice_line_flowService);
    }
    return this._create_invoice_line_flow.run(inParams);
  }
   
   

   
 
  private _create_or_update_billing_contract_line_detail_flow: Invoices_create_or_update_billing_contract_line_detail_flowService;
  public async create_or_update_billing_contract_line_detail_flow(inParams: { billingContractLineId: number, strategyName: string, value?: string, data?: { ScheduleType?: string, Frequency?: string, DailyInterval?: number, WeeklyInterval?: number, Monday?: boolean, Tuesday?: boolean, Wednesday?: boolean, Thursday?: boolean, Friday?: boolean, Saturday?: boolean, Sunday?: boolean, MonthlyInterval?: number, MonthlyDayNumber?: number, OccursAt?: string, AdvancedBilling?: boolean, AdvanceBillingGracePeriod?: number, AdvanceBillingGraceFrequency?: string, AnniversaryBillingGracePeriod?: number, AnniversaryBillingGraceFrequency?: string, TagIds?: number[] } }): Promise< { success?: boolean, reason?: string, xml?: string }> {
    if(!this._create_or_update_billing_contract_line_detail_flow) {
      this._create_or_update_billing_contract_line_detail_flow = this.injector.get(Invoices_create_or_update_billing_contract_line_detail_flowService);
    }
    return this._create_or_update_billing_contract_line_detail_flow.run(inParams);
  }
   
   

   
 
  private _create_reasoncode_flow: Invoices_create_reasoncode_flowService;
  public async create_reasoncode_flow(inParams: { properties?: any }): Promise< { reasons?: string[], reasonCodeId?: number }> {
    if(!this._create_reasoncode_flow) {
      this._create_reasoncode_flow = this.injector.get(Invoices_create_reasoncode_flowService);
    }
    return this._create_reasoncode_flow.run(inParams);
  }
   
   

   
 
  private _deactivate_billing_contract_flow: Invoices_deactivate_billing_contract_flowService;
  public async deactivate_billing_contract_flow(inParams: { contractId: number }): Promise< { success?: boolean, messages?: string[] }> {
    if(!this._deactivate_billing_contract_flow) {
      this._deactivate_billing_contract_flow = this.injector.get(Invoices_deactivate_billing_contract_flowService);
    }
    return this._deactivate_billing_contract_flow.run(inParams);
  }
   
   

   
 
  private _delete_billing_contract_flow: Invoices_delete_billing_contract_flowService;
  public async delete_billing_contract_flow(inParams: { billingContractId?: number }): Promise< { reasons?: string[] }> {
    if(!this._delete_billing_contract_flow) {
      this._delete_billing_contract_flow = this.injector.get(Invoices_delete_billing_contract_flowService);
    }
    return this._delete_billing_contract_flow.run(inParams);
  }
   
   

   
 
  private _delete_billing_contract_line_flow: Invoices_delete_billing_contract_line_flowService;
  public async delete_billing_contract_line_flow(inParams: { billingContractLineId: number }): Promise< { result?: string, success?: boolean }> {
    if(!this._delete_billing_contract_line_flow) {
      this._delete_billing_contract_line_flow = this.injector.get(Invoices_delete_billing_contract_line_flowService);
    }
    return this._delete_billing_contract_line_flow.run(inParams);
  }
   
   

   
 
  private _delete_billing_task_flow: Invoices_delete_billing_task_flowService;
  public async delete_billing_task_flow(inParams: { billingTaskId: number }): Promise< { reason?: string }> {
    if(!this._delete_billing_task_flow) {
      this._delete_billing_task_flow = this.injector.get(Invoices_delete_billing_task_flowService);
    }
    return this._delete_billing_task_flow.run(inParams);
  }
   
   

   
 
  private _delete_invoice_flow: Invoices_delete_invoice_flowService;
  public async delete_invoice_flow(inParams: { invoiceId: number }): Promise< { reason?: string }> {
    if(!this._delete_invoice_flow) {
      this._delete_invoice_flow = this.injector.get(Invoices_delete_invoice_flowService);
    }
    return this._delete_invoice_flow.run(inParams);
  }
   
   

   
 
  private _delete_invoice_line_flow: Invoices_delete_invoice_line_flowService;
  public async delete_invoice_line_flow(inParams: { invoiceLineId: number }): Promise< { reason?: string }> {
    if(!this._delete_invoice_line_flow) {
      this._delete_invoice_line_flow = this.injector.get(Invoices_delete_invoice_line_flowService);
    }
    return this._delete_invoice_line_flow.run(inParams);
  }
   
   

   
 
  private _delete_invoicing_project_rule_priority: Invoices_delete_invoicing_project_rule_priorityService;
  public async delete_invoicing_project_rule_priority(inParams: { id?: string, ruleId?: string }): Promise< { exception_id?: string }> {
    if(!this._delete_invoicing_project_rule_priority) {
      this._delete_invoicing_project_rule_priority = this.injector.get(Invoices_delete_invoicing_project_rule_priorityService);
    }
    return this._delete_invoicing_project_rule_priority.run(inParams);
  }
   
   

   
 
  private _delete_invoicing_rule_template_exception: Invoices_delete_invoicing_rule_template_exceptionService;
  public async delete_invoicing_rule_template_exception(inParams: { rule_id?: string, project_id?: number, exception?: string, id?: string }): Promise< { exception_id?: string }> {
    if(!this._delete_invoicing_rule_template_exception) {
      this._delete_invoicing_rule_template_exception = this.injector.get(Invoices_delete_invoicing_rule_template_exceptionService);
    }
    return this._delete_invoicing_rule_template_exception.run(inParams);
  }
   
   

   
 
  private _freight_billing_details_storage: Invoices_freight_billing_details_storageService;
  public async freight_billing_details_storage(inParams: { payload?: $frontendTypes.Invoices.FreightBillingDetails[], action: string, skip?: number, take?: number, date_from?: string, date_to?: string, full_text_search?: string }): Promise< { payload?: $frontendTypes.Invoices.FreightBillingDetails[], success?: boolean, error?: any[], count?: number }> {
    if(!this._freight_billing_details_storage) {
      this._freight_billing_details_storage = this.injector.get(Invoices_freight_billing_details_storageService);
    }
    return this._freight_billing_details_storage.run(inParams);
  }
   
   

   
 
  private _freight_billing_header_storage: Invoices_freight_billing_header_storageService;
  public async freight_billing_header_storage(inParams: { payload?: $frontendTypes.Invoices.FreightBillingHeader[], action: string, skip?: number, take?: number, date_from?: string, date_to?: string, full_text_search?: string }): Promise< { payload?: $frontendTypes.Invoices.FreightBillingHeader[], success?: boolean, error?: any[], count?: number }> {
    if(!this._freight_billing_header_storage) {
      this._freight_billing_header_storage = this.injector.get(Invoices_freight_billing_header_storageService);
    }
    return this._freight_billing_header_storage.run(inParams);
  }
   
   

   
 
  private _freight_billing_process_accessorial_charge_flow: Invoices_freight_billing_process_accessorial_charge_flowService;
  public async freight_billing_process_accessorial_charge_flow(inParams: { order_id?: number }): Promise< { results?: any }> {
    if(!this._freight_billing_process_accessorial_charge_flow) {
      this._freight_billing_process_accessorial_charge_flow = this.injector.get(Invoices_freight_billing_process_accessorial_charge_flowService);
    }
    return this._freight_billing_process_accessorial_charge_flow.run(inParams);
  }
   
   

   
 
  private _freight_billing_process_orders: Invoices_freight_billing_process_ordersService;
  public async freight_billing_process_orders(inParams: {  }): Promise< { result?: any }> {
    if(!this._freight_billing_process_orders) {
      this._freight_billing_process_orders = this.injector.get(Invoices_freight_billing_process_ordersService);
    }
    return this._freight_billing_process_orders.run(inParams);
  }
   
   

   
 
  private _initialize_invoicing_configuration: Invoices_initialize_invoicing_configurationService;
  public async initialize_invoicing_configuration(inParams: {  }): Promise<void> {
    if(!this._initialize_invoicing_configuration) {
      this._initialize_invoicing_configuration = this.injector.get(Invoices_initialize_invoicing_configurationService);
    }
    return this._initialize_invoicing_configuration.run(inParams);
  }
   
   

   
 
  private _insert_invoicing_project_rule_priority: Invoices_insert_invoicing_project_rule_priorityService;
  public async insert_invoicing_project_rule_priority(inParams: { rule_id: string, project_id: number, priority: number }): Promise< { exception_id?: string }> {
    if(!this._insert_invoicing_project_rule_priority) {
      this._insert_invoicing_project_rule_priority = this.injector.get(Invoices_insert_invoicing_project_rule_priorityService);
    }
    return this._insert_invoicing_project_rule_priority.run(inParams);
  }
   
   

   
 
  private _insert_invoicing_rule_template_exception: Invoices_insert_invoicing_rule_template_exceptionService;
  public async insert_invoicing_rule_template_exception(inParams: { rule_id: string, project_id: number, exception: string }): Promise< { exception_id?: string }> {
    if(!this._insert_invoicing_rule_template_exception) {
      this._insert_invoicing_rule_template_exception = this.injector.get(Invoices_insert_invoicing_rule_template_exceptionService);
    }
    return this._insert_invoicing_rule_template_exception.run(inParams);
  }
   
   

   
 
  private _invoicing_confirm_requests: Invoices_invoicing_confirm_requestsService;
  public async invoicing_confirm_requests(inParams: {  }): Promise< { messages?: any[] }> {
    if(!this._invoicing_confirm_requests) {
      this._invoicing_confirm_requests = this.injector.get(Invoices_invoicing_confirm_requestsService);
    }
    return this._invoicing_confirm_requests.run(inParams);
  }
   
   

   
 
  private _invoicing_create_invoice_lines: Invoices_invoicing_create_invoice_linesService;
  public async invoicing_create_invoice_lines(inParams: { output?: boolean, billing_tasks?: any[] }): Promise< { messages?: any[] }> {
    if(!this._invoicing_create_invoice_lines) {
      this._invoicing_create_invoice_lines = this.injector.get(Invoices_invoicing_create_invoice_linesService);
    }
    return this._invoicing_create_invoice_lines.run(inParams);
  }
   
   

   
 
  private _invoicing_create_invoices: Invoices_invoicing_create_invoicesService;
  public async invoicing_create_invoices(inParams: { instructionId: string, termId: number, entities: { entityType?: string, entityReference?: string, entityId?: number, projectId?: number }[], useExistingInvoice?: boolean }): Promise< { invoices?: { invoiceId?: number, entityId?: number, entityType?: string, projectId?: number }[], messages?: any[] }> {
    if(!this._invoicing_create_invoices) {
      this._invoicing_create_invoices = this.injector.get(Invoices_invoicing_create_invoicesService);
    }
    return this._invoicing_create_invoices.run(inParams);
  }
   
   

   
 
  private _invoicing_create_transactions: Invoices_invoicing_create_transactionsService;
  public async invoicing_create_transactions(inParams: { rule: $frontendTypes.Invoices.Instruction, minTaskDate: string }): Promise< { messages?: any[] }> {
    if(!this._invoicing_create_transactions) {
      this._invoicing_create_transactions = this.injector.get(Invoices_invoicing_create_transactionsService);
    }
    return this._invoicing_create_transactions.run(inParams);
  }
   
   

   
 
  private _invoicing_instruction: Invoices_invoicing_instructionService;
  public async invoicing_instruction(inParams: { payload?: $frontendTypes.Invoices.Instruction, action?: string, skip?: number, take?: number, search?: string }): Promise< { payload?: $frontendTypes.Invoices.Instruction[], success?: boolean, error?: any[] }> {
    if(!this._invoicing_instruction) {
      this._invoicing_instruction = this.injector.get(Invoices_invoicing_instructionService);
    }
    return this._invoicing_instruction.run(inParams);
  }
   
   

   
 
  private _invoicing_log: Invoices_invoicing_logService;
  public async invoicing_log(inParams: { payload?: $frontendTypes.Invoices.Log, action?: string, skip?: number, take?: number }): Promise< { payload?: $frontendTypes.Invoices.Log[], success?: boolean, error?: any[], count?: number }> {
    if(!this._invoicing_log) {
      this._invoicing_log = this.injector.get(Invoices_invoicing_logService);
    }
    return this._invoicing_log.run(inParams);
  }
   
   

   
 
  private _invoicing_main: Invoices_invoicing_mainService;
  public async invoicing_main(inParams: {  }): Promise< { messages?: any[] }> {
    if(!this._invoicing_main) {
      this._invoicing_main = this.injector.get(Invoices_invoicing_mainService);
    }
    return this._invoicing_main.run(inParams);
  }
   
   

   
 
  private _invoicing_option: Invoices_invoicing_optionService;
  public async invoicing_option(inParams: { payload?: { id?: string, dimension?: string, value?: string, order?: number }, action?: string, skip?: number, take?: number, search?: string }): Promise< { payload?: { id?: string, dimension?: string, value?: string, order?: number }[], success?: boolean, error?: any[] }> {
    if(!this._invoicing_option) {
      this._invoicing_option = this.injector.get(Invoices_invoicing_optionService);
    }
    return this._invoicing_option.run(inParams);
  }
   
   

   
 
  private _invoicing_project_rule_priority_health_check: Invoices_invoicing_project_rule_priority_health_checkService;
  public async invoicing_project_rule_priority_health_check(inParams: {  }): Promise<void> {
    if(!this._invoicing_project_rule_priority_health_check) {
      this._invoicing_project_rule_priority_health_check = this.injector.get(Invoices_invoicing_project_rule_priority_health_checkService);
    }
    return this._invoicing_project_rule_priority_health_check.run(inParams);
  }
   
   

   
 
  private _invoicing_purge: Invoices_invoicing_purgeService;
  public async invoicing_purge(inParams: {  }): Promise<void> {
    if(!this._invoicing_purge) {
      this._invoicing_purge = this.injector.get(Invoices_invoicing_purgeService);
    }
    return this._invoicing_purge.run(inParams);
  }
   
   

   
 
  private _invoicing_transaction: Invoices_invoicing_transactionService;
  public async invoicing_transaction(inParams: { payload?: $frontendTypes.Invoices.Transaction[], action?: string, skip?: number, take?: number }): Promise< { payload?: $frontendTypes.Invoices.Transaction[], success?: boolean, error?: any[] }> {
    if(!this._invoicing_transaction) {
      this._invoicing_transaction = this.injector.get(Invoices_invoicing_transactionService);
    }
    return this._invoicing_transaction.run(inParams);
  }
   
   

   
 
  private _invoicing_transaction_test: Invoices_invoicing_transaction_testService;
  public async invoicing_transaction_test(inParams: {  }): Promise< { output?: any[] }> {
    if(!this._invoicing_transaction_test) {
      this._invoicing_transaction_test = this.injector.get(Invoices_invoicing_transaction_testService);
    }
    return this._invoicing_transaction_test.run(inParams);
  }
   
   

   
 
  private _is_billing_contract_activatable_flow: Invoices_is_billing_contract_activatable_flowService;
  public async is_billing_contract_activatable_flow(inParams: { billingContractID: number }): Promise< { reason?: string }> {
    if(!this._is_billing_contract_activatable_flow) {
      this._is_billing_contract_activatable_flow = this.injector.get(Invoices_is_billing_contract_activatable_flowService);
    }
    return this._is_billing_contract_activatable_flow.run(inParams);
  }
   
   

   
 
  private _is_billing_contract_copyable_flow: Invoices_is_billing_contract_copyable_flowService;
  public async is_billing_contract_copyable_flow(inParams: { billingContractID: number }): Promise< { reason?: string }> {
    if(!this._is_billing_contract_copyable_flow) {
      this._is_billing_contract_copyable_flow = this.injector.get(Invoices_is_billing_contract_copyable_flowService);
    }
    return this._is_billing_contract_copyable_flow.run(inParams);
  }
   
   

   
 
  private _is_billing_contract_deactivatable_flow: Invoices_is_billing_contract_deactivatable_flowService;
  public async is_billing_contract_deactivatable_flow(inParams: { billingContractID: number }): Promise< { reason?: string }> {
    if(!this._is_billing_contract_deactivatable_flow) {
      this._is_billing_contract_deactivatable_flow = this.injector.get(Invoices_is_billing_contract_deactivatable_flowService);
    }
    return this._is_billing_contract_deactivatable_flow.run(inParams);
  }
   
   

   
 
  private _is_billing_contract_deletable_flow: Invoices_is_billing_contract_deletable_flowService;
  public async is_billing_contract_deletable_flow(inParams: { billingContractID: number }): Promise< { reasons?: string[] }> {
    if(!this._is_billing_contract_deletable_flow) {
      this._is_billing_contract_deletable_flow = this.injector.get(Invoices_is_billing_contract_deletable_flowService);
    }
    return this._is_billing_contract_deletable_flow.run(inParams);
  }
   
   

   
 
  private _is_billing_contract_line_deletable_flow: Invoices_is_billing_contract_line_deletable_flowService;
  public async is_billing_contract_line_deletable_flow(inParams: { billingContractLineId: number }): Promise< { reason?: string }> {
    if(!this._is_billing_contract_line_deletable_flow) {
      this._is_billing_contract_line_deletable_flow = this.injector.get(Invoices_is_billing_contract_line_deletable_flowService);
    }
    return this._is_billing_contract_line_deletable_flow.run(inParams);
  }
   
   

   
 
  private _is_billing_contract_line_valid_flow: Invoices_is_billing_contract_line_valid_flowService;
  public async is_billing_contract_line_valid_flow(inParams: { billingContractId: number, billingContractLineIds?: number[] }): Promise< { reasons?: string[], valid?: boolean }> {
    if(!this._is_billing_contract_line_valid_flow) {
      this._is_billing_contract_line_valid_flow = this.injector.get(Invoices_is_billing_contract_line_valid_flowService);
    }
    return this._is_billing_contract_line_valid_flow.run(inParams);
  }
   
   

   
 
  private _is_billing_task_cancelable_flow: Invoices_is_billing_task_cancelable_flowService;
  public async is_billing_task_cancelable_flow(inParams: { billingTaskId: number }): Promise< { reason?: string }> {
    if(!this._is_billing_task_cancelable_flow) {
      this._is_billing_task_cancelable_flow = this.injector.get(Invoices_is_billing_task_cancelable_flowService);
    }
    return this._is_billing_task_cancelable_flow.run(inParams);
  }
   
   

   
 
  private _is_billing_task_deletable_flow: Invoices_is_billing_task_deletable_flowService;
  public async is_billing_task_deletable_flow(inParams: { billingTaskId: number }): Promise< { reason?: string }> {
    if(!this._is_billing_task_deletable_flow) {
      this._is_billing_task_deletable_flow = this.injector.get(Invoices_is_billing_task_deletable_flowService);
    }
    return this._is_billing_task_deletable_flow.run(inParams);
  }
   
   

   
 
  private _is_billing_task_invoicable_flow: Invoices_is_billing_task_invoicable_flowService;
  public async is_billing_task_invoicable_flow(inParams: { billingTaskId: number }): Promise< { reason?: string }> {
    if(!this._is_billing_task_invoicable_flow) {
      this._is_billing_task_invoicable_flow = this.injector.get(Invoices_is_billing_task_invoicable_flowService);
    }
    return this._is_billing_task_invoicable_flow.run(inParams);
  }
   
   

   
 
  private _is_invoice_cancelable: Invoices_is_invoice_cancelableService;
  public async is_invoice_cancelable(inParams: { invoiceId: number }): Promise< { reason?: string }> {
    if(!this._is_invoice_cancelable) {
      this._is_invoice_cancelable = this.injector.get(Invoices_is_invoice_cancelableService);
    }
    return this._is_invoice_cancelable.run(inParams);
  }
   
   

   
 
  private _is_invoice_completable: Invoices_is_invoice_completableService;
  public async is_invoice_completable(inParams: { invoiceId: number }): Promise< { reason?: string }> {
    if(!this._is_invoice_completable) {
      this._is_invoice_completable = this.injector.get(Invoices_is_invoice_completableService);
    }
    return this._is_invoice_completable.run(inParams);
  }
   
   

   
 
  private _is_invoice_deletable: Invoices_is_invoice_deletableService;
  public async is_invoice_deletable(inParams: { invoiceId: number }): Promise< { reason?: string }> {
    if(!this._is_invoice_deletable) {
      this._is_invoice_deletable = this.injector.get(Invoices_is_invoice_deletableService);
    }
    return this._is_invoice_deletable.run(inParams);
  }
   
   

   
 
  private _is_invoice_exportable: Invoices_is_invoice_exportableService;
  public async is_invoice_exportable(inParams: { invoiceId: number }): Promise< { reason?: string }> {
    if(!this._is_invoice_exportable) {
      this._is_invoice_exportable = this.injector.get(Invoices_is_invoice_exportableService);
    }
    return this._is_invoice_exportable.run(inParams);
  }
   
   

   
 
  private _is_invoice_line_deletable: Invoices_is_invoice_line_deletableService;
  public async is_invoice_line_deletable(inParams: { invoiceLineId: number }): Promise< { reason?: string }> {
    if(!this._is_invoice_line_deletable) {
      this._is_invoice_line_deletable = this.injector.get(Invoices_is_invoice_line_deletableService);
    }
    return this._is_invoice_line_deletable.run(inParams);
  }
   
   

   
 
  private _migrate_storage_to_utilities: Invoices_migrate_storage_to_utilitiesService;
  public async migrate_storage_to_utilities(inParams: {  }): Promise< { success?: boolean, error?: any[] }> {
    if(!this._migrate_storage_to_utilities) {
      this._migrate_storage_to_utilities = this.injector.get(Invoices_migrate_storage_to_utilitiesService);
    }
    return this._migrate_storage_to_utilities.run(inParams);
  }
   
   

   
 
  private _submit_entity_import_json_request: Invoices_submit_entity_import_json_requestService;
  public async submit_entity_import_json_request(inParams: { entityImport?: any, referenceCode?: string, project?: string, warehouse?: string, groupId?: string, transactionGroupId?: string }): Promise< { requestId?: string }> {
    if(!this._submit_entity_import_json_request) {
      this._submit_entity_import_json_request = this.injector.get(Invoices_submit_entity_import_json_requestService);
    }
    return this._submit_entity_import_json_request.run(inParams);
  }
   
   

   
 
  private _update_billing_task_flow: Invoices_update_billing_task_flowService;
  public async update_billing_task_flow(inParams: { billingTaskId: number, invoiceLineId?: number, notes?: string }): Promise< { reason?: string }> {
    if(!this._update_billing_task_flow) {
      this._update_billing_task_flow = this.injector.get(Invoices_update_billing_task_flowService);
    }
    return this._update_billing_task_flow.run(inParams);
  }
   
   

   
 
  private _update_invoice_status_flow: Invoices_update_invoice_status_flowService;
  public async update_invoice_status_flow(inParams: { invoiceId: number, statusId: number, notes?: string }): Promise< { reason?: string }> {
    if(!this._update_invoice_status_flow) {
      this._update_invoice_status_flow = this.injector.get(Invoices_update_invoice_status_flowService);
    }
    return this._update_invoice_status_flow.run(inParams);
  }
   
   

   
 
  private _update_invoicing_project_rule_priority: Invoices_update_invoicing_project_rule_priorityService;
  public async update_invoicing_project_rule_priority(inParams: { priority_id: string, priority: number }): Promise< { exception_id?: string }> {
    if(!this._update_invoicing_project_rule_priority) {
      this._update_invoicing_project_rule_priority = this.injector.get(Invoices_update_invoicing_project_rule_priorityService);
    }
    return this._update_invoicing_project_rule_priority.run(inParams);
  }
   
   

   
}

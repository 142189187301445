import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_shipping_label_reportServiceInParams {
  orderId?: number, shipmentId?: number, copies?: number}

interface IReports_custom_shipping_label_reportServiceData {
  data?: { result?: { Labels?: { OrderId?: number, OrderLookupCode?: string, OwnerReference?: string, ProjectLookupCode?: string, ProjectName?: string, OwnerLookupCode?: string, OwnerName?: string, ShipmentId?: number, ShipmentLookupCode?: string, ShippedDate?: string, TaskId?: number, Employee?: string, LicensePlateLookupCode?: string, Lots?: { LookupCode?: string, MaterialId?: number }[], Pallets?: { PalletClassId?: number, Amount?: number }[] }[] } }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_shipping_label_reportService extends ReportBaseService<IReports_custom_shipping_label_reportServiceInParams, IReports_custom_shipping_label_reportServiceData> {

  protected reportReferenceName = 'custom_shipping_label_report';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_shipping_label_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_shipping_label_reportServiceInParams): Promise<IReports_custom_shipping_label_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IReports_custom_shipping_label_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_shipping_label_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId ,
        shipmentId:  $report.inParams.shipmentId ,
        copies:  $report.inParams.copies 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_shipping_label_report_multiple.get(dsParams);
      
      data.data = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}

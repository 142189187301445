import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';
import { PrintNode_ReportService } from './PrintNode.report.index';
import { Inventory_ReportService } from './Inventory.report.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Reports_ReportService } from './Reports.report.index';
import { FootPrintApiManager_ReportService } from './FootPrintApiManager.report.index';


@Injectable({ providedIn: 'root' })
export class app_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.PrintNode = this.injector.get(PrintNode_ReportService);
    this.Inventory = this.injector.get(Inventory_ReportService);
    this.Invoices = this.injector.get(Invoices_ReportService);
    this.Reports = this.injector.get(Reports_ReportService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_ReportService);
  }
    public Utilities: Utilities_ReportService;
    public PrintNode: PrintNode_ReportService;
    public Inventory: Inventory_ReportService;
    public Invoices: Invoices_ReportService;
    public Reports: Reports_ReportService;
    public FootPrintApiManager: FootPrintApiManager_ReportService;

  public app: app_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
}


import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';
import { PrintNode_FlowService } from './PrintNode.flow.index';
import { Inventory_FlowService } from './Inventory.flow.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Reports_FlowService } from './Reports.flow.index';
import { FootPrintApiManager_FlowService } from './FootPrintApiManager.flow.index';

import { app_custom_freight_billing_process_accessorial_charge_flowService } from './app.flow.index';
import { app_custom_freight_billing_process_ordersService } from './app.flow.index';
import { app_custom_get_report_data_flowService } from './app.flow.index';
import { app_custom_print_labelsService } from './app.flow.index';
import { app_custom_print_request_flowService } from './app.flow.index';
import { app_test_report_printingService } from './app.flow.index';

import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
    this.PrintNode = this.injector.get(PrintNode_FlowService);
    this.Inventory = this.injector.get(Inventory_FlowService);
    this.Invoices = this.injector.get(Invoices_FlowService);
    this.Reports = this.injector.get(Reports_FlowService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_FlowService);
  }

    public Utilities: Utilities_FlowService;
    public PrintNode: PrintNode_FlowService;
    public Inventory: Inventory_FlowService;
    public Invoices: Invoices_FlowService;
    public Reports: Reports_FlowService;
    public FootPrintApiManager: FootPrintApiManager_FlowService;
  public app: app_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _custom_freight_billing_process_accessorial_charge_flow: app_custom_freight_billing_process_accessorial_charge_flowService;
  public async custom_freight_billing_process_accessorial_charge_flow(inParams: { order_id?: number }): Promise< { results?: any }> {
    if(!this._custom_freight_billing_process_accessorial_charge_flow) {
      this._custom_freight_billing_process_accessorial_charge_flow = this.injector.get(app_custom_freight_billing_process_accessorial_charge_flowService);
    }
    return this._custom_freight_billing_process_accessorial_charge_flow.run(inParams);
  }
   
   

   
 
  private _custom_freight_billing_process_orders: app_custom_freight_billing_process_ordersService;
  public async custom_freight_billing_process_orders(inParams: {  }): Promise< { paramDetails?: any, result?: any }> {
    if(!this._custom_freight_billing_process_orders) {
      this._custom_freight_billing_process_orders = this.injector.get(app_custom_freight_billing_process_ordersService);
    }
    return this._custom_freight_billing_process_orders.run(inParams);
  }
   
   

   
 
  private _custom_get_report_data_flow: app_custom_get_report_data_flowService;
  public async custom_get_report_data_flow(inParams: { package_name: string, report_name: string, inputs?: any }): Promise< { report_data?: Blob }> {
    if(!this._custom_get_report_data_flow) {
      this._custom_get_report_data_flow = this.injector.get(app_custom_get_report_data_flowService);
    }
    return this._custom_get_report_data_flow.run(inParams);
  }
   
   

   
 
  private _custom_print_labels: app_custom_print_labelsService;
  public async custom_print_labels(inParams: { order_id?: number, shipment_id?: number, order_line_number?: number, copies?: number, printer_name: string, report_name: string }): Promise<void> {
    if(!this._custom_print_labels) {
      this._custom_print_labels = this.injector.get(app_custom_print_labelsService);
    }
    return this._custom_print_labels.run(inParams);
  }
   
   

   
 
  private _custom_print_request_flow: app_custom_print_request_flowService;
  public async custom_print_request_flow(inParams: { printerId: number, content: string, contentType?: string, rotate?: number }): Promise< { outputDetails?: string, printJobId?: number }> {
    if(!this._custom_print_request_flow) {
      this._custom_print_request_flow = this.injector.get(app_custom_print_request_flowService);
    }
    return this._custom_print_request_flow.run(inParams);
  }
   
   

   
 
  private _test_report_printing: app_test_report_printingService;
  public async test_report_printing(inParams: {  }): Promise<void> {
    if(!this._test_report_printing) {
      this._test_report_printing = this.injector.get(app_test_report_printingService);
    }
    return this._test_report_printing.run(inParams);
  }
   
   

   
}
